//  Core

.MuiInputBase-root:not(.MuiInput-underline) {
  transition: $transition-base;
}

body {
  .MuiInputBase-input {
    background: white;
    border-radius: 4px;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
  }
  .MuiTextField-root {
    border-radius: 4px;
  }
  .MuiFormControlLabel-root {
    margin: 0px 0px;
  }
  .MuiTextField-root.text-white-50 {
    .MuiInputBase-root,
    .MuiInputLabel-root {
      color: rgba($white, 0.8);
    }

    .MuiOutlinedInput-root,
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba($white, 0.5);

      &:hover {
        border-color: rgba($white, 0.8);
      }
    }
  }

  .MuiChip-root {
    background: $primary;
    color: color-yiq($primary);

    .MuiChip-deleteIcon {
      color: color-yiq($primary);
    }

    &.Mui-disabled {
      opacity: 0.65;
    }
  }

  .MuiChip-deletable:focus {
    background-color: $first !important;
  }
}
