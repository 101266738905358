// Core

@import 'core';

// Widgets

@import 'widgets';

// Components

@import 'components';

.panel {
  background: white;
  padding: 20px;
  border-radius: 4px;
}
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

h4 {
  color: $gray-900;
}

.d-flex {
  display: flex;
}

.d-space-between {
  justify-content: space-between;
}

.d-align-center {
  align-items: center;
}

.d-col {
  flex-direction: column;
}

.input-heading {
  font-size: $font-size-base;
  font-weight: bold;
  margin: 0.5rem 0 0.3rem;
  padding: 0;
  color: $gray-800;
  display: block;

  &--large {
    font-size: $font-size-lg * 1.2;
  }
}

//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto !important;
  padding: 0 !important;
}

.app-wrapper {
  display: flex;
  flex: 1;
  background: $page-bg;
  overflow-x: hidden;
}

.app-main {
  display: flex;
  flex: 1 auto;
  flex-grow: 1;
  min-width: 0;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;

  &--inner {
    padding: $spacer * 2;
    padding-top: $spacer;
    margin-bottom: $spacer;
    display: flex;
    flex: 1;

    @include media-breakpoint-down(sm) {
      padding: $spacer;
    }

    &__wrapper {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.select-form-control {
  margin: -4px;
}

.home-greeting {
  h3 {
    font-size: $font-size-lg * 1.75;
    font-weight: bold;
    color: $gray-900;
    margin-bottom: $spacer * 0.5;
  }

  p {
    font-size: $font-size-base * 1.2;
    color: $gray-900;
  }
}

.heading-tabs {
  margin: (-($spacer * 2)) (-($spacer * 2)) 0;
  padding-top: $spacer;

  @include media-breakpoint-down(sm) {
    margin-left: -($spacer);
    margin-right: -($spacer);
  }
}

.pointer {
  cursor: pointer;
}

.paper-tabs {
  overflow-x: scroll;
  text-align: left;
}

.filter-card {
  min-width: 300px;
  width: 100%;
}

.sub-detail-row {
  width: 100%;
  margin-bottom: $spacer;
  display: table;
  font-size: $font-size-lg;

  &-title,
  &-value {
    display: table-cell;
  }

  &-title {
    width: 33%;
    color: $gray-900;
  }

  &-value {
    padding-left: 4px;
    font-weight: bold;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;

    &-title {
      width: 50%;
    }
  }
}

.plain-input {
  .MuiInputBase-root {
    box-shadow: none !important;
  }

  fieldset {
    border: 0 !important;
  }
}

.banner {
  .btn-icon {
    flex-shrink: 0;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  &-info {
    display: flex;
    align-items: center;
    margin-right: $spacer * 2;
    font-size: $font-size-lg;

    .btn-icon {
      margin-right: $spacer * 1.5;
    }

    p {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &-content {
      align-items: flex-start;
    }

    .banner-action {
      position: absolute;
      right: 0;
      top: 0;
    }

    &-info {
      flex-direction: column;
      text-align: center;
      margin-right: 0;
      width: 100%;

      p {
        margin-top: $spacer;
        margin-bottom: $spacer;
      }

      * {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
}

.page-title-tabs {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: none !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.status-container {
  padding: 16px;
  background: white;
  margin: -1rem -2rem;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .status-col {
    display: flex;
    flex-direction: column;
  }

  .status-row {
    display: flex;
    align-items: center;
    margin: 4px;
  }
}

.video-grid {
  margin: 15px -2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 600px));
  gap: 2px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  @include media-breakpoint-down(sm) {
    margin: 15px -1rem;
  }
}

.video-box {
  position: relative;
  color: white;
  /* border: 1px solid white; */
  margin: 1px;
  // aspect-ratio: 16 / 9;
  height: 300px;
  width: 100%;

  &--active {
    border: 4px solid red;
  }
}

.call-noise-indicator {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 999;
  color: #f8353c;
  font-size: 4.25rem !important;
}

.video-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  padding-left: 12px;
  background: rgba(54, 54, 54, 0.7);
  display: flex;
  align-items: center;
  font-weight: bold;
}

.local-video {
  box-sizing: content-box;
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  border: 2px solid red;
  height: 200px;
  width: 300px;

  video {
    width: 300px;
    height: 200px;
    border: none;
  }
}

video {
  background: black;
  /* border: 1px solid white; */
  height: 100%;
  width: 100%;
}

.user-select-none {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}

.alert-card {
  min-height: 50px;
  cursor: pointer;
}

.bay-card-data {
  margin: 3px 0;
}

.action-button {
  padding: 6px !important;
  background-color: #57be8c !important;
  color: white !important;

  &:hover {
    background-color: #319866 !important;
  }

  &:disabled,
  &[disabled] {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }
}

.response-time-card {
  border: 1px solid $gray-400;

  &--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    padding: 0 1.25rem 1.25rem;
  }

  .MuiCollapse-wrapper {
    background: $secondary;
    border-top: 1px solid $gray-400;
  }

  &--image {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }

  &--trend {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    & * {
      font-size: $font-size-base !important;
    }
  }
}

.response-time-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  flex-grow: 1;

  &--avg {
    font-size: $font-size-lg * 1.1;
    font-weight: bold;
  }

  span {
    display: block;
  }
}

.ranked-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  flex-wrap: wrap;

  &:first-child() {
    border-top: 1px solid $gray-600;
  }

  &-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;

    &--title {
      font-size: $font-size-lg;
      font-weight: bold;
    }

    &--value {
      font-size: $font-size-sm;
    }
  }

  &-sparkline {
    margin: 0 1rem;
    text-align: center;
    // max-width: 300px;
    flex-grow: 1;
    flex: 2;
  }

  &-trend {
    font-size: $font-size-lg * 1.3;
    font-weight: bold;
    width: 60px;
    text-align: right;
  }
}

.contain {
  object-fit: contain !important;
}

.image-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;
  height: 124px;
  margin: 0.5rem 0;
  padding: 0 0.5rem;

  &--image {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }

  span {
    font-size: $font-size-base;
    display: block;
  }
}

.overall-stat-card {
  width: fit-content;
  margin: 0 auto;

  & > div {
    width: fit-content;
  }

  @include media-breakpoint-up(xl) {
    .image-stat:not(:last-child) {
      border-right: 1px solid $gray-600;
    }
  }
}

.hallmonitor-card {
  .MuiGrid-container {
    & > .hallmonitor-left-panel {
      border-right: 1px solid $gray-600;
      padding-right: 1rem !important;
    }

    & > :last-child() {
      padding-left: 1rem !important;
    }
  }

  .hallmonitor-isolation {
    &-low {
      background: #5ec04e !important;
    }

    &-medium {
      background: #ff832a !important;
    }

    &-high {
      background: #f0443f !important;
    }

    &-droplet {
      background: #e485bf !important;
    }

    &-title {
      font-size: $font-size-lg * 1.1;
    }
  }

  .hallmonitor-isolation-title {
    font-size: 1.2rem;
  }

  @include media-breakpoint-down(sm) {
    .card-header-title h4 {
      font-size: $font-size-lg !important;
    }

    .hallmonitor-isolation-title {
      font-size: 1.2rem;
    }

    .MuiGrid-container {
      & > .hallmonitor-left-panel {
        border-right: none !important;
        border-bottom: 1px solid $gray-600;
        padding-bottom: 1rem;
      }

      & > :last-child() {
        padding-top: 1rem !important;
      }
    }
  }
}

.fall-risk-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffd335;
  color: black;
}

/// REVERTED STYLING
.statCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  padding: 0.5rem;
  text-align: center;
  margin: 0 auto;

  &--image {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--info {
    flex-grow: 1;
    margin-left: 1.5rem;
  }

  &--large {
    .statCard--image {
      width: 130px;
      height: 130px;
    }

    .statCard--info {
      margin-left: 0;
    }

    h4 {
      margin-top: 1rem;
    }
  }
}

.statCard img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.statCard h4 {
  margin-bottom: 0.5rem;
  font-size: 18px;
}

.statCard h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.statCard--large img {
  width: 130px;
  height: 130px;
}

.statCard--large h3 {
  font-size: 24px;
}

.statCard--horizontal {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.small-menu li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin: 0 !important;
  border-radius: 0 !important;

  & .MuiCheckbox-root {
    padding: 6px !important;
  }
}

.device-controller {
  &-info,
  &-room {
    border-bottom: 1px solid $gray-600;
  }

  &-room {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    gap: $spacer;
    padding: $spacer * 1.5 $spacer;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      max-width: 300px;
      margin: 0.75rem 0;
    }
  }
}

.discrete {
  outline: none;
  border: none;
}

/* also need keyframes and -moz-keyframes */
@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

.dialog-content {
  width: 550px;
  padding: 8px;
}

.list-item {
  height: 40px;
}

.list-container {
  overflow-y: auto;
  max-height: 400px;
  min-height: 400px;
}

img{
  -webkit-touch-callout: none !important; 
   -webkit-user-select: none !important; 
}