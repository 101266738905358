// Collapsed Sidebar
@include media-breakpoint-up(md) {
  .app-sidebar-collapsed {
    .app-sidebar:not(:hover) {
      .app-sidebar--content {

        .sidebar-navigation {

          .sidebar-menu-box {
            padding: ($layout-spacer / 6);

            .sidebar-header {
              display: none;
            }
          }

          .sidebar-header {
            padding-top: 0;
            padding-bottom: 0;
            height: 0;
            margin: ($layout-spacer / 2);

            &::after {
              visibility: visible;
              opacity: 1;
              height: 1px;
              content: '';
              position: absolute;
              width: 80%;
              left: 10%;
            }

            span {
              visibility: hidden;
              opacity: 0;
              height: 0;
              overflow: hidden;
            }
          }

          ul {
            li {

              a {
                font-size: 0;

                .sidebar-icon {
                  margin: 0 auto;
                }

                .sidebar-icon-indicator {
                  opacity: 0;
                  visibility: hidden;
                  height: 0;
                  width: 0;
                  overflow: hidden;
                  display: none;
                }

                .sidebar-item-label {
                  opacity: 0;
                  height: 0;
                  overflow: hidden;
                  width: 0;
                  visibility: hidden;

                  .badge {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }

              ul {
                height: 0;
                padding: 0;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}

// Collapsed Sidebar
@include media-breakpoint-up(md) {
  .app-sidebar-collapsed {
    .app-sidebar {
      // width: $sidebar-width-collapsed;
      position: fixed;
      left: 0;
      display: flex;

      &:hover:not(.app-sidebar--mini) {
        width: $sidebar-width;
        position: fixed;
        box-shadow: $sidebar-shadow;

        & > div {
          width: $sidebar-width;
        }
      }
    }

    .app-content {
      margin-left: $sidebar-width-collapsed;
    }
  }
}
