//  Variables

// General

$sidebar-navlink-height:            44px;
$sidebar-navlink-inner-height:      36px;
$sidebar-header-font-size:          ($font-size-xs / 1.2);
$sidebar-navlink-font-size:         $font-size-base * 1.06;
$sidebar-icon-font-size:            $font-size-lg * 1.3;
$sidebar-submenu-navlink-font-size: $font-size-xs;
// Variables

// General

$sidebar-width:             243px;
$app-sidebar-transition:    cubic-bezier(0.685, 0.0473, 0.346, 1);
// Collapsed

$sidebar-width-collapsed:   90px;

// Light color scheme

$sidebar-bg-light:          $white;
$sidebar-shadow-light:      0px 0px 28px 0px rgba($primary, 0.08);
$sidebar-content-bg-light:  transparent;
// Dark color scheme

$sidebar-bg:                #253053;
$sidebar-shadow:            $box-shadow-layout-right;
$sidebar-content-bg:        transparent;
