.react-select__control {
  min-height: 56px !important;
}

.react-select__multi-value__label {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.react-select__menu {
  z-index: 2 !important;
  background-color: white !important;
}
