.toast-success {
  background-color: #24a148 !important;
  border-radius: 4px !important;
}

.toast-warning {
  background-color: #f1c21b !important;
  border-radius: 4px !important;
}

.toast-error {
  background-color: #da1e28 !important;
  border-radius: 4px !important;
}

.toast-info {
  border-radius: 4px !important;
}

.toast-info .Toastify__toast-body {
  color: #444 !important;
}