//  Core

.sidebar-navigation {
  .sidebar-menu-box {
    @include border-radius($border-radius-sm);
    margin: ($layout-spacer / 3);
    padding: ($layout-spacer / 3);

    ul {
      margin: 0;

      li a {
        font-size: $font-size-sm;
        @include border-radius($border-radius-sm);
        padding: 0 ($layout-spacer / 4);

        .sidebar-item-label .badge {
          right: ($layout-spacer / 4);
        }
      }
    }

    ul li ul li a {
      padding: 0 0 0 ($layout-spacer / 2);

      &::after {
        display: none;
      }
    }

    .sidebar-header {
      padding: 0 ($layout-spacer / 4) ($layout-spacer / 4);
    }
  }

  .sidebar-header {
    text-transform: uppercase;
    font-size: $sidebar-header-font-size;
    padding: ($layout-spacer / 3) ($layout-spacer / 2) ($layout-spacer / 4);
    height: initial;
    transition: $transition-base;
    white-space: nowrap;
    margin: 0;
    position: relative;

    &::after {
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: $transition-base;
      content: '';
    }
  }

  ul {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin: 0;
    li {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 2px 0;

      & > a {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        height: $sidebar-navlink-height;
        padding: 0 ($layout-spacer / 4);
        font-size: $sidebar-navlink-font-size;
        white-space: nowrap;
        font-weight: bold;
        .sidebar-item-label {
          height: 22px;
          line-height: 22px;
          opacity: 1;
          width: auto;
          visibility: visible;
          overflow: visible;

          .badge {
            position: absolute;
            right: ($layout-spacer / 2);
            height: 22px;
            line-height: 24px;
            top: 50%;
            margin-top: -11px;
            transition: $transition-base;
            opacity: 1;
            visibility: visible;
          }
        }

        .sidebar-icon-indicator {
          transform: rotate(0deg);
          transition: $transition-base;
          opacity: 0.4;
          margin-left: auto;
          width: 18px;
          height: 18px;

          & > * {
            max-height: 100%;
            width: 100%;
            display: block;
          }
        }

        &.nav-link-simple {
          .sidebar-icon-indicator-right {
            transform: rotate(0deg) !important;
          }
        }

        .sidebar-icon {
          margin: 0 ($spacer / 1.8) 0 0;
          width: 54px;
          min-width: 54px;
          height: 54px;
          display: flex;
          transition: $transition-base;
          align-items: center;
          align-content: center;

          & > * {
            max-height: 90%;
            width: 90%;
            display: block;
            transition: $transition-base;
          }
        }

        &.active-section {
          .sidebar-icon-indicator {
            transform: rotate(-90deg);
          }

          &.nav-link-simple {
            .sidebar-icon-indicator-right {
              transform: rotate(0deg) !important;
            }
          }
        }
      }

      ul {
        overflow: hidden;
        padding: ($layout-spacer / 4) 0 ($layout-spacer / 3);
        transition: $transition-base;

        li {
          padding: 1px ($layout-spacer / 2);

          a {
            @include border-radius($border-radius);
            font-size: $sidebar-submenu-navlink-font-size;
            position: relative;
            margin: 0;
            font-weight: normal;
            padding: 0 ($layout-spacer / 1.3);
            height: $sidebar-navlink-inner-height;
            white-space: nowrap;
            transition: transform 0.2s, height 0.3s, opacity 0.3s, margin 0.3s,
              color 0.3s, background-color 0.3s;
            display: flex;

            .sidebar-item-label {
              transition: none;
            }

            &::after {
              content: '';
              left: 12px;
              transition: $transition-base;
              width: 8px;
              height: 8px;
              top: 50%;
              position: absolute;
              margin: -4px 0 0 -4px;
              opacity: 1;
              @include border-radius($border-radius-lg);
            }

            &:hover,
            &.active {
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}

.app-sidebar {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;

  &--shadow {
    box-shadow: $box-shadow-layout-right !important;
  }

  &--light {
    background: $sidebar-bg-light;

    &--content {
      background: $sidebar-content-bg-light;
    }

    perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
    .ps .ps__rail-y:hover {
      background-color: rgba($black, 0.03) !important;
    }

    .ps__thumb-y {
      background-color: rgba($black, 0.05) !important;
    }

    .sidebar-navigation {
      .sidebar-menu-box {
        background: rgba($primary, 0.03);
      }

      .sidebar-header {
        color: rgba($black, 0.85);
      }

      ul {
        height: 100%;
        li {
          & > a {
            color: rgba($black, 0.8);
            height: 54px;
            .sidebar-icon {
              & > * {
                color: $primary;
                opacity: 0.45;
              }
            }

            .sidebar-icon-indicator {
              opacity: 0.4;
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, 0.08);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, 0.12);
                  }
                }
              }
            }
          }
        }

        li > a:hover,
        li > a.active {
          color: $primary;
          background: rgba($primary, 0.05);

          .sidebar-icon-indicator {
            opacity: 0.7;
          }

          .sidebar-icon {
            & > * {
              opacity: 1;
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.app-sidebar-user {
  border-bottom: 1px solid #dfdfe0;
  transition: all 0.2s $app-sidebar-transition;

  &--active {
    .app-sidebar-user--dropdown-btn {
      transform: rotate(-180deg);
    }
  }

  &--profile {
    transition: all 0.2s $app-sidebar-transition;
    cursor: pointer;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  &--icon {
    @include border-radius($border-radius-sm);
    width: 40px;
    height: 40px;
    background: $gray-900;
    display: flex;
    align-items: center;
    align-content: center;
    box-shadow: 1px 1px 3px rgba($black, 0.2);
    padding: 4px;

    img {
      width: 26px;
      margin: 0 auto;
    }
  }

  &--text {
    line-height: 1;
    transition: all 0.5s $app-sidebar-transition;
    display: flex;
    margin-left: 0.5rem;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;

    span {
      display: block;
      font-size: $font-size-sm;
      color: $gray-800;
      opacity: 0.7;
    }

    b {
      font-size: $font-size-base * 1.2;
      font-weight: bold;
      color: $gray-900;
      margin: 0;
      margin-bottom: 4px;
    }
  }

  &--dropdown-btn {
    margin-left: auto;
    transition: all 0.3s $app-sidebar-transition;
  }

  &--dropdown {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
  }

  &:hover {
    color: $black;
  }
}

.app-sidebar-collapsed {
  .app-sidebar--light:not(:hover) {
    .app-sidebar-user {
      justify-content: center;
    }

    .app-sidebar-user {
      &--text,
      &--dropdown-btn,
      &--dropdown {
        display: none;
      }
    }
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($black, 0.15);
          }
        }
      }
    }
  }
}

@import './collapsed';
